import React from 'react'
import classNames from 'classnames';

import Layout from '../components/layout'

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: ''
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
  }

  handleChangeName(e) {
    this.setState({name: e.target.value});
  }

  handleChangeEmail(e) {
    this.setState({email: e.target.value});
  }

  handleChangeMessage(e) {
    this.setState({message: e.target.value});
  }

  handleClick(e) {
    e.preventDefault();

    const timestamp = new Date(Date.now()).toISOString();
    const spreadsheetId = 'AKfycbzCabhb95k7_K9dMYorQvZRsZiZGPpF4WPLwZG9YI-0Ev0rP30'
    const url = `https://script.google.com/macros/s/${spreadsheetId}/exec`

    const params = Object.entries(this.state).map(([k, v]) => {
      return `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
    }).join('&');

    fetch(`${url}?${params}&timestamp=${timestamp}`, { method: 'GET' }).then(response => {
      this.setState({
        showSuccess: true,
        showError: false,
      });
    }).catch(e => {
      this.setState({
        showSuccess: false,
        showError: true,
      });
    });
  }

  render() {
    const { showError, showSuccess } = this.state;

    return (
      <Layout>
        <div id='contact'>
          <h2 className='major'>Contact</h2>
          <div className={classNames({ hidden: !showSuccess })}>
            <p>Thanks!</p>
          </div>
          <div className={classNames({ hidden: !showError })}>
            <p>An error occurred while submitting your form, please email <a href='mailto:admin@grassfed.network'>admin@grassfed.network</a> directly.</p>
          </div>
          <form className={classNames({ hidden: showSuccess })}>
            <div className='field half first'>
              <label htmlFor='name'>Name</label>
              <input type='text' name='name' id='name' value={this.state.name} onChange={this.handleChangeName} />
            </div>
            <div className='field half'>
              <label htmlFor='email'>Email</label>
              <input type='text' name='email' id='email' value={this.state.email} onChange={this.handleChangeEmail} />
            </div>
            <div className='field'>
              <label htmlFor='message'>Message</label>
              <textarea name='message' id='message' rows='4' value={this.state.message} onChange={this.handleChangeMessage}></textarea>
            </div>
            <ul className='actions'>
              <li><input type='submit' value='Send Message' className='special' onClick={this.handleClick} /></li>
              <li><input type='reset' value='Reset' /></li>
            </ul>
          </form>
          <ul className='icons'>
            <li><a href='http://twitter.com/@coinfund_io' className='icon fa-twitter'><span className='label'>Twitter</span></a></li>
            <li><a href='http://www.medium.com/@coinfund_io' className='icon fa-medium'><span className='label'>Instagram</span></a></li>
            <li><a href='https://www.facebook.com/CoinFund/' className='icon fa-facebook'><span className='label'>Facebook</span></a></li>
          </ul>
        </div>
      </Layout>
    );
  }
}

export default ContactPage
